<template>
  <v-hover
    v-slot="{ hover }"
  >
    <router-link :to="target" target= '_blank'>
      <v-sheet
        :elevation="hover ? 12 : 2"
        class="mx-auto pointer trans"
        :width="width"
        :min-width="width"
        :max-height="height"
        outlined
        shaped
      >
        <div class="text-center orange--text text--lighten-3 pa-0 ma-0" :class="{'my-4': isVertical}">
          <div :class="flexClass">
            <p :class="[isVertical ? 'vertical' : 'horizontical text-left', 'text']">
              {{text1}}
            </p>
            <v-icon large color="blue lighten-4">
              {{'mdi-arrow-' + direction + '-drop-circle-outline'}}
            </v-icon>
            <p :class="[isVertical ? 'vertical' : 'horizontical text-right', 'text']">
              {{text2}}
            </p>
          </div>
        </div>
      </v-sheet>
    </router-link>
  </v-hover>
</template>

<script>
  export default {
    name: 'NavCtrl',
    props: {
      direction: String,
      text1: String,
      text2: String,
      target: String,
    },
    data() {
      return {}
    },
    computed: {
      isVertical: function () { 
        if (this.direction.toLowerCase() === 'right' || this.direction.toLowerCase() === 'left') {
          return true
        }
        return false
      },
      width: function () { 
        if (this.direction.toLowerCase() === 'right' || this.direction.toLowerCase() === 'left') {
          return '35'
        }
        return '300'
      },
      height: function () { 
        if (this.direction.toLowerCase() === 'right' || this.direction.toLowerCase() === 'left') {
          return '500'
        }
        return '35'
      },
      flexClass: function () { 
        if (this.direction.toLowerCase() === 'right' || this.direction.toLowerCase() === 'left') {
          return 'd-flex flex-column mb-6'
        }
        return 'd-flex pa-0'
      }
    },
    methods: {
      handleResize () {
      }
    }
  }
</script>

<style>
</style>

<style lang="scss" scoped>
  .trans {
    background-color: rgba(114, 114, 114, 0.5);
  }
  .pointer {
    cursor: pointer;
  }
  p.vertical {
    text-orientation: upright;
    writing-mode: vertical-lr;
    min-height: 8em;
  }
  p.horizontical {
    letter-spacing: 3px;
  }
  p.text {
    width: 100%;
    font-size:1.2em;
    font-weight: 600; 
    // writing-mode: vertical-lr; 
    margin: 0 !important;
    padding: 0 !important;
    display:flex;
    justify-content:center;
    align-items:center;
    // background-color: coral;
    line-height: 2em;
  }
  p.text-left {
    justify-content: flex-end;
  }
  p.text-right {
    justify-content: flex-start;
  }
  .transparentbg {
    background: linear-gradient(0deg, #dedede55 45%, #dedede55 100%);
    // background: linear-gradient(0deg, #00000066 30%, #ffb74d44 100%);
  }
  
</style>
